import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import DatePicker from "../../elements/DatePicker";
import CancelIcon from "@material-ui/icons/Cancel";
import { useMutation } from "react-query";
import { editProvider } from "../../../api/API";
import { useSnackBar } from "../../providers/SnackBarProvider";
import { defaultErrMsg, notEnoughCharError } from "../../../constants/Constants";

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: "pointer",
  },
  dialogTitle: {
    paddingTop: 0,
    textTransform: 'uppercase',
  },
  button: {
    minWidth: 130,
  },
  textField: {
    '&>:nth-child(odd)': {
      width: "49%",
    },
    '&>:nth-child(even)': {
      width: "49%",
    },
  },
});

export default function ModalAddEditProvider({ provider, onClose }) {
  const { showSnackBar } = useSnackBar();
  const classes = useStyles();
  //form data
  const [formState, setFormState] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [editProviderMutation] = useMutation(
    ({
      id = null,
      name,
      registrationNumber,
      key,
      dateFrom,
      dateTo,
      address,
      vatNumber,
      bankName,
      bankAccount,
      economicCategoryCode,
    }) =>
      editProvider({
        ProviderId: id,
        ProviderName: name,
        ProviderRegNr: registrationNumber,
        ProviderUUID: key,
        ProviderValidFrom: dateFrom
          ? moment(dateFrom).format("DD.MM.YYYY")
          : "",
        ProviderValidTill: dateTo ? moment(dateTo).format("DD.MM.YYYY") : "",
        ProviderAddress: address,
        ProviderVatNr: vatNumber,
        ProviderBankName: bankName,
        ProviderBankAccount: bankAccount,
        ProviderEconCateCode: economicCategoryCode,
      }),
    {
      onSuccess: () => {
        showSnackBar();
        onClose();
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        }),
    }
  );

  React.useEffect(() => {
    setFormState({
      id: provider?.ProviderId,
      name: provider?.ProviderName || "",
      registrationNumber: provider?.ProviderRegNr || "",
      key: provider?.ProviderUUID || "",
      dateFrom: provider?.ProviderValidFrom
        ? moment(provider.ProviderValidFrom, "DD.MM.YYYY").toDate()
        : null,
      dateTo: provider?.ProviderValidTill
        ? moment(provider.ProviderValidTill, "DD.MM.YYYY").toDate()
        : null,
      address: provider?.ProviderAddress || "",
      vatNumber: provider?.ProviderVatNr || "",
      bankName: provider?.ProviderBankName || "",
      bankAccount: provider?.ProviderBankAccount || "",
      economicCategoryCode: provider?.ProviderEconCateCode || "",
    });
  }, [provider]);

  const onFieldChange = ({ target }) => {
    if (target.name === "economicCategoryCode") {
      let inputValue = target.value.replace(/\D/g, '');
  
      setFormState({ ...formState, [target.name]: inputValue });
    } else {
      setFormState({ ...formState, [target.name]: target.value });
    }
  };

  const isFormValid = () => {
    const { name, registrationNumber, key, economicCategoryCode } = formState;
    const _errors = [];
    if (!name) _errors["name"] = defaultErrMsg;
    if (!registrationNumber) _errors["registrationNumber"] = defaultErrMsg;
    if (!key) _errors["key"] = defaultErrMsg;
    if (economicCategoryCode !== "" && economicCategoryCode.length < 4) _errors["economicCategoryCode"] = notEnoughCharError;

    setFormErrors(_errors);

    return Object.keys(_errors).length === 0;
  };

  const onSubmit = () => {
    if (!isFormValid()) return;
    editProviderMutation(formState);
  };

  return (
    <Dialog open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Tirgotājs
            </Box>
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="dense"
              label="Nosaukums"
              fullWidth
              name="name"
              autoComplete="off"
              value={formState.name}
              onChange={onFieldChange}
              helperText={formErrors.name}
              error={Boolean(formErrors.name)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Registrācijas Nr."
              fullWidth
              name="registrationNumber"
              autoComplete="off"
              value={formState.registrationNumber}
              onChange={onFieldChange}
              helperText={formErrors.registrationNumber}
              error={Boolean(formErrors.registrationNumber)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Atslēga"
              fullWidth
              name="key"
              autoComplete="off"
              value={formState.key}
              onChange={onFieldChange}
              helperText={formErrors.key}
              error={Boolean(formErrors.key)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Adrese"
              fullWidth
              name="address"
              autoComplete="off"
              value={formState.address}
              onChange={onFieldChange}
              helperText={formErrors.address}
              error={Boolean(formErrors.address)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="PVN numurs"
              fullWidth
              name="vatNumber"
              autoComplete="off"
              value={formState.vatNumber}
              onChange={onFieldChange}
              helperText={formErrors.vatNumber}
              error={Boolean(formErrors.vatNumber)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Banka"
              fullWidth
              name="bankName"
              autoComplete="off"
              value={formState.bankName}
              onChange={onFieldChange}
              helperText={formErrors.bankName}
              error={Boolean(formErrors.bankName)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Bankas konts"
              fullWidth
              name="bankAccount"
              autoComplete="off"
              value={formState.bankAccount}
              onChange={onFieldChange}
              helperText={formErrors.bankAccount}
              error={Boolean(formErrors.bankAccount)}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              flexWrap="wrap"
              className={classes.textField}
            >
              <DatePicker
                label="No"
                value={formState.dateFrom}
                onChange={(date) =>
                  setFormState({
                    ...formState,
                    dateFrom: date,
                  })
                }
                error={Boolean(formErrors.dateFrom)}
              />
              <DatePicker
                label="Līdz"
                value={formState.dateTo}
                onChange={(date) =>
                  setFormState({ ...formState, dateTo: date })
                }
              />
              <TextField
                variant="outlined"
                margin="dense"
                label="EKK"
                name="economicCategoryCode"
                autoComplete="off"
                inputProps={{ maxLength: 7 }}
                value={formState.economicCategoryCode}
                onChange={onFieldChange}
                helperText={formErrors.economicCategoryCode}
                error={Boolean(formErrors.economicCategoryCode)}
              />
            </Box>
            
          </DialogContent>
          <DialogActions>
            <Box px={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                SAGLABĀT
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}
